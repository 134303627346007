// Helpers
export function priceCalculator(course_info) {
  for (var i in course_info) {
    if (course_info[i].program.value === "data-essentials") {
      course_info[i].price = 1495;
    } else if (course_info[i].program.value === "data-full-stack") {
      course_info[i].price = 6995;
    } else if (course_info[i].program.value === "data-lead") {
      course_info[i].price = 2995;
    } else if (course_info[i].program.value === "cyber-essentials") {
      course_info[i].price = 1495;
    } else if (course_info[i].program.value === "cyber-full-stack") {
      course_info[i].price = 6995;
    } else if (course_info[i].program.value === "analysis-full-stack") {
      course_info[i].price = 6995;
    } else if (course_info[i].program.value === "analysis-lead") {
      course_info[i].price = 2995;
    }
  }

  return course_info;
}

const courseWeight = {
  "data-essentials": 1,
  "data-full-stack": 2,
  "data-lead": 3,
  "analysis-full-stack": 4,
  "analysis-lead": 5,
  "cyber-essentials": 6,
  "cyber-full-stack": 7,
};

/**
 * This function calculates the discount for a corresponding course combination.
 *
 * It takes the course_info array and combine the `course.program.key` into a
 * single string like "data-essentials-data-full-stack". We can compare the
 * string with the different combinations.
 *
 * Warning! There is an order in the string:
 * data-essentials-data-full-stack-data-lead-cyber-essentials-cyber-full-stack.
 *
 * The order is given by courseWeight.
 *
 * @param {Array} course_info - Array of course objects.
 */
export function discountCalculator(course_info) {
  // No discount if only one item
  if (course_info.length === 1) {
    return 0;
  }

  let totalPrice = course_info.reduce((acc, course) => acc + course.price, 0);
  let discount = 0;

  if (totalPrice < 5001) {
    discount = 300;
  } else if (totalPrice >= 5001 && totalPrice < 10001) {
    discount = 495;
  } else if (totalPrice >= 10001 && totalPrice < 15001) {
    discount = 990;
  } else {
    discount = 1150;
  }

  // let enrolledCourses = course_info.map((course) => {
  //   return course.program.key;
  // });

  // let coursesCombo = enrolledCourses
  //   .sort((a, b) => courseWeight[a] - courseWeight[b])
  //   .join("-");

  // if (
  //   ["data-essentials-cyber-essentials", "data-lead-cyber-essentials"].includes(
  //     coursesCombo
  //   )
  // ) {
  //   discount = 300;
  // } else if (
  //   [
  //     "data-essentials-data-full-stack",
  //     "data-full-stack-data-lead",
  //     "cyber-essentials-cyber-full-stack",
  //     "data-essentials-analysis-full-stack",
  //     "data-full-stack-cyber-essentials",
  //     "data-essentials-data-full-stack-cyber-essentials",
  //     "data-essentials-cyber-full-stack",
  //     "data-lead-cyber-full-stack",
  //     "data-essentials-analysis-lead",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 495;
  // } else if (
  //   [
  //     "data-essentials-cyber-essentials-cyber-full-stack",
  //     "data-lead-cyber-essentials-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 595;
  // } else if (
  //   ["data-essentials-data-full-stack-data-lead-cyber-essentials"].includes(
  //     coursesCombo
  //   )
  // ) {
  //   discount = 695;
  // } else if (["data-full-stack-cyber-full-stack"].includes(coursesCombo)) {
  //   discount = 795;
  // } else if (
  //   [
  //     "data-full-stack-cyber-essentials-cyber-full-stack",
  //     "data-essentials-data-full-stack-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 895;
  // } else if (
  //   ["data-full-stack-data-lead-cyber-full-stack"].includes(coursesCombo)
  // ) {
  //   discount = 950;
  // } else if (
  //   [
  //     "data-essentials-data-full-stack-data-lead",
  //     "data-essentials-analysis-full-stack-analysis-lead",
  //     "data-full-stack-data-lead-cyber-essentials",
  //     "data-essentials-data-full-stack-cyber-essentials-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 990;
  // } else if (
  //   [
  //     "data-full-stack-data-lead-cyber-essentials-cyber-full-stack",
  //     "data-essentials-data-full-stack-data-lead-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 1050;
  // } else if (
  //   [
  //     "data-essentials-data-full-stack-data-lead-cyber-essentials-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 1150;
  // } else if (
  //   [
  //     "data-essentials-data-full-stack-data-lead-analysis-full-stack-analysis-lead",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 4880;
  // } else if (
  //   ["data-essentials-data-full-stack-analysis-full-stack"].includes(
  //     coursesCombo
  //   )
  // ) {
  //   discount = 690;
  // } else if (["data-essentials-analysis-lead"].includes(coursesCombo)) {
  //   discount = 1195;
  // } else if (
  //   [
  //     "data-essentials-data-full-stack-data-lead-cyber-essentials-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   discount = 4395;
  // }

  return discount;
}

export function installmentCalculator(values) {
  // let enrolledCourses = values.enrolledCourses.map(
  //   (course) => course.program.key
  // );

  // let coursesCombo = enrolledCourses
  //   .sort((a, b) => courseWeight[a] - courseWeight[b])
  //   .join("-");

  let installments = [
    { text: "1", value: 1, disabled: false },
    { text: "2", value: 2, disabled: false },
    { text: "3", value: 3, disabled: false },
    // { text: "4", value: 4, disabled: false },
    // { text: "5", value: 5, disabled: false },
    // { text: "6", value: 6, disabled: false },
    // { text: "7", value: 7, disabled: false },
    // { text: "8", value: 8, disabled: false },
    // { text: "9", value: 9, disabled: false },
    // { text: "10", value: 10, disabled: false },
    // { text: "11", value: 11, disabled: false },
    // { text: "12", value: 12, disabled: false },
    // { text: "13", value: 13, disabled: false },
  ];

  // if (
  //   [
  //     "data-essentials-data-full-stack-cyber-essentials-cyber-full-stack",
  //     "data-full-stack-data-lead-cyber-essentials-cyber-full-stack",
  //     "data-essentials-data-full-stack-data-lead-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   return installments.slice(0, 12);
  // } else if (
  //   [
  //     "data-full-stack-cyber-essentials-cyber-full-stack",
  //     "data-full-stack-cyber-full-stack",
  //     "data-essentials-data-full-stack-cyber-full-stack",
  //     "data-full-stack-data-lead-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   return installments.slice(0, 11);
  // } else if (
  //   ["data-essentials-data-full-stack-data-lead-cyber-essentials"].includes(
  //     coursesCombo
  //   )
  // ) {
  //   // Case essentials cyber + essentials data + fullstack + lead track
  //   // Plan until 10
  //   return installments.slice(0, 10);
  // } else if (
  //   [
  //     "data-essentials-data-full-stack-data-lead",
  //     "data-essentials-data-full-stack-cyber-essentials",
  //     "data-full-stack-data-lead-cyber-essentials",
  //   ].includes(coursesCombo)
  // ) {
  //   // Case essentials (cyber || data) + fullstack + lead track
  //   // Plan until 9
  //   return installments.slice(0, 9);
  // } else if (
  //   [
  //     "data-full-stack-data-lead",
  //     "data-essentials-cyber-essentials-cyber-full-stack",
  //     "data-lead-cyber-essentials-cyber-full-stack",
  //     "data-lead-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   // Case fullstack + lead
  //   // Case fullstack track (data essentials + data FS) + essentials cyber
  //   // Plan until 8
  //   return installments.slice(0, 8);
  // } else if (
  //   [
  //     "data-essentials-data-full-stack",
  //     "cyber-essentials-cyber-full-stack",
  //     "data-full-stack-cyber-essentials",
  //     "data-essentials-cyber-full-stack",
  //   ].includes(coursesCombo)
  // ) {
  //   return installments.slice(0, 7);
  // } else if (["data-full-stack", "cyber-full-stack"].includes(coursesCombo)) {
  //   // Case only fullstack
  //   // Plan until 6
  //   return installments.slice(0, 6);
  // } else if (["data-lead-cyber-essentials"].includes(coursesCombo)) {
  //   // Case lead + (essentials data || cyber)
  //   // Plan until 5
  //   return installments.slice(0, 5);
  // } else if (
  //   ["data-lead", "data-essentials-cyber-essentials"].includes(coursesCombo)
  // ) {
  //   // Case lead
  //   // Case data essentials + cyber essentials
  //   // Plan until 4
  //   return installments.slice(0, 4);
  // } else if (["data-essentials", "cyber-essentials"].includes(coursesCombo)) {
  //   // Case essentials only || cyber essentials only
  //   // Plan until 3
  //   return installments.slice(0, 3);
  // }

  return installments;
}

export function getCertificationPrice(course_info, certificationChoice) {
  let enrolledCourses = course_info.map((course) => {
    return course.program.key;
  });

  let coursesCombo = enrolledCourses
    .sort((a, b) => courseWeight[a] - courseWeight[b])
    .join("-");

  if (!certificationChoice) {
    return 0;
  }

  if (
    [
      "data-essentials",
      "data-lead",
      "cyber-essentials",
      "cyber-full-stack",
      "cyber-essentials-cyber-full-stack",
      "analysis-full-stack",
      "analysis-lead",
      "data-lead-analysis-lead",
      "data-essentials-cyber-essentials",
      "data-lead-cyber-essentials",
      "data-essentials-cyber-essentials-cyber-full-stack",
      "data-lead-cyber-essentials-cyber-full-stack",
    ].includes(coursesCombo)
  ) {
    return 250;
  } else if (
    [
      "data-essentials-analysis-full-stack",
      "data-essentials-analysis-full-stack-analysis-lead",
    ].includes(coursesCombo)
  ) {
    return 300;
  }

  return 450;
}
