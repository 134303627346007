import React, { useContext } from "react";
import { ClientSecret } from "./collectPayment";
import {
  priceCalculator,
  discountCalculator,
  getCertificationPrice,
} from "../helpers/helpers";
import { useStripe } from "@stripe/react-stripe-js";
import { Row, Col } from "react-bootstrap";
import { Icon, Dropdown, Message, Checkbox } from "semantic-ui-react";
import {
  StepJedha,
  FormJedha,
  Fieldset,
  FieldTitle,
  Label,
  Input,
  FormSubmit,
} from "../style/style";
import { useHistory } from "react-router-dom";
import axios from "axios";

const CollectUserInfo = () => {
  const stripe = useStripe();
  const history = useHistory();
  const { values, setValues } = useContext(ClientSecret);
  // Certification toggle
  const [certificationChoice, setCertificationChoice] = React.useState(true);
  const [certificationDisabled, setCertificationDisabled] =
    React.useState(false);

  // Use to switch the certification value choice
  const handleCertificationChoice = () => {
    setValues((prevState) => ({
      ...prevState,
      certificationChoice: !certificationChoice,
    }));
    setCertificationChoice(!certificationChoice);
  };

  const options = [
    {
      key: "data-essentials",
      text: "Data Essentials",
      value: "data-essentials",
      price: "1495",
    },
    {
      key: "data-full-stack",
      text: "Data Full Stack",
      value: "data-full-stack",
      price: "6995",
    },
    { key: "data-lead", text: "Data Lead", value: "data-lead", price: "2995" },
    {
      key: "analysis-full-stack",
      text: "Data Analysis Full Stack",
      value: "analysis-full-stack",
      price: "6995",
    },
    {
      key: "analysis-lead",
      text: "Data Analysis Lead",
      value: "analysis-lead",
      price: "2995",
    },
    {
      key: "cyber-essentials",
      text: "Cybersecurity Essentials",
      value: "cyber-essentials",
      price: "1495",
    },
    {
      key: "cyber-full-stack",
      text: "Cybersecurity Full Stack",
      value: "cyber-full-stack",
      price: "6995",
    },
  ];

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEnrolledCourses = (event, data) => {
    setCertificationChoice(true);
    setCertificationDisabled(false);

    // get enrolled course
    const { value } = data;

    // Find enrolled course text property
    var get_texts = [];
    for (var i in value) {
      for (var j in options) {
        if (value[i] === options[j].value) {
          get_texts.push({
            program: options[j],
            campus: values?.enrolledCourses[i]?.campus || "paris",
          });
        }
      }
    }

    setValues((prevState) => ({ ...prevState, enrolledCourses: get_texts }));

    // Handle disabling certification
    if (
      get_texts.some((course) => course.program.value === "cyber-full-stack") &&
      get_texts.length === 1
    ) {
      setCertificationChoice(false);
      setCertificationDisabled(true);
      setValues((prevState) => ({
        ...prevState,
        certificationChoice: false,
      }));
    }

    // Validation step
    if (
      get_texts.some((course) => course.program.value === "data-essentials") &&
      get_texts.some((course) => course.program.value === "data-lead") &&
      get_texts.length === 2
    ) {
      setValues((prevState) => ({ ...prevState, enrolledCoursesError: true }));
    } else {
      setValues((prevState) => ({ ...prevState, enrolledCoursesError: false }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Calculate total price
    const pricedCourses = priceCalculator(values.enrolledCourses);
    const discount = discountCalculator(pricedCourses);
    let certificationPrice = getCertificationPrice(
      pricedCourses,
      certificationChoice
    );

    const totalPrice =
      pricedCourses.reduce(
        (previousPrice, currentPrice) =>
          previousPrice + parseInt(currentPrice["price"] || 0),
        0
      ) -
      discount +
      certificationPrice;

    setValues((prevState) => ({ ...prevState, price: totalPrice.toString() }));

    // Create a Customer on Stripe
    const createCustomerRequest =
      process.env.REACT_APP_API_LINK + "create-customer";
    axios
      .post(
        createCustomerRequest,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          address: values.address,
          city: values.city,
          postCode: values.postCode,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const client_secret = response["data"]["client_secret"];
        const client_id = response["data"]["client_id"];
        setValues((prevState) => ({
          ...prevState,
          client_secret: client_secret,
          client_id: client_id,
        }));
        let path = `/payment-info`;
        history.push(path);
      });
  };

  return (
    <div>
      <div className="d-none col-8 ml-auto mr-auto mt-4 d-md-flex justify-content-center">
        <StepJedha.Group unstackable fluid>
          <StepJedha active>
            <Icon name="user" />
            <StepJedha.Content>
              <StepJedha.Title>Informations d'inscription</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
          <StepJedha>
            <Icon name="payment" />
            <StepJedha.Content>
              <StepJedha.Title>Informations Bancaire</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
          <StepJedha>
            <Icon name="info" />
            <StepJedha.Content>
              <StepJedha.Title>Confirmation</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
        </StepJedha.Group>
      </div>
      <div className="col-sm-12 col-md-8 m-auto">
        <FormJedha
          className="p-4 mt-5 d-flex flex-column justify-content-center"
          onSubmit={handleSubmit}
        >
          <section>
            <FieldTitle className="mb-4">
              Informations de facturation{" "}
              <span role="img" aria-label="ok">
                👌
              </span>
            </FieldTitle>
            <Fieldset className="p-0 mb-5">
              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Prénom</span>
                    <Input
                      name="firstName"
                      className="col-8"
                      placeholder="Jean"
                      defaultValue={values.firstName}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Nom</span>
                    <Input
                      name="lastName"
                      className="col-8"
                      placeholder="Valjean"
                      defaultValue={values.lastName}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Email</span>
                    <Input
                      name="email"
                      className="col-8"
                      placeholder="jean@valjean.com"
                      defaultValue={values.email}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Adresse</span>
                    <Input
                      name="address"
                      className="col-8"
                      placeholder="2 rue Victor Hugo"
                      defaultValue={values.address}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>

              <Row className="d-flex flex-sm-row flex-column justify-content-center">
                <Col className="col-md-4">
                  <Label className="m-0 d-flex col-12 align-items-center justify-content-center pl-lg-3">
                    <span className="col-sm-5 col-md-4 col-lg-3 text-right">
                      Ville
                    </span>
                    <Input
                      name="city"
                      className="col-8 pl-lg-4"
                      placeholder="Paris"
                      defaultValue={values.city}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>

                <Col className="col-md-4">
                  <Label className="m-0 d-flex col-12 align-items-center justify-content-center">
                    <span className="p-0 col-sm-5 col-md-10 col-lg-5 text-right">
                      Code Postal
                    </span>
                    <Input
                      name="postCode"
                      className="col-8"
                      placeholder="75013"
                      defaultValue={values.postCode}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>
            </Fieldset>
          </section>
          <section>
            <FieldTitle className="mb-4">
              Informations liées à votre programme{" "}
              <span role="img" aria-label="student">
                🧑‍🎓
              </span>
            </FieldTitle>
            <Fieldset className="choose-campus pb-0 pt-2 mb-5">
              <Row>
                <Col>
                  <Label className="m-0 col-12 d-flex align-items-center justify-content-around">
                    <span>Quelle(s) session(s) souhaitez-vous faire ?</span>
                    <Dropdown
                      className="col-6"
                      placeholder="Sélectionnez une session"
                      fluid
                      multiple
                      selection
                      options={options}
                      onChange={handleEnrolledCourses}
                    />
                  </Label>
                </Col>
              </Row>
            </Fieldset>
          </section>
          <section>
            <FieldTitle className="mb-4">
              Certification{" "}
              <span role="img" aria-label="ok">
                👩‍🎓👨‍🎓
              </span>
            </FieldTitle>
            <Fieldset className="col-12 py-3">
              <Row>
                <Col className="d-flex flex-row align-items-center col-12">
                  <div
                    className="col-6 text-right"
                    style={{
                      fontWeight: "bold",
                      color: "#1f4352",
                    }}
                  >
                    Voulez-vous passer la certification ?
                  </div>
                  <div className="col-6 d-flex flex-row">
                    <Checkbox
                      disabled={certificationDisabled}
                      toggle
                      checked={certificationChoice}
                      className="CheckBoxJedha align-items-center"
                      onClick={
                        !certificationDisabled
                          ? handleCertificationChoice
                          : () => {}
                      }
                    />
                    <div className="mx-3">
                      {certificationChoice ? "Oui" : "Non"}
                    </div>
                  </div>
                </Col>
              </Row>
            </Fieldset>
          </section>
          <FormSubmit
            className="m-auto col-12"
            variant="primary"
            type="submit"
            disabled={
              !stripe ||
              !values.firstName ||
              !values.lastName ||
              !values.address ||
              !values.city ||
              !values.postCode ||
              values.enrolledCoursesError ||
              values.enrolledCampusError
            }
          >
            SUIVANT
          </FormSubmit>

          {values.enrolledCoursesError ? (
            <Message
              error
              header="Erreur de choix de programmes"
              content="Veuillez vérifier que vous ayez choisi la bonne combinaison de programmes"
            />
          ) : null}

          {values.enrolledCampusError ? (
            <Message
              error
              header="Erreur de choix de campus"
              content="Il est possible que vous ayez choisi un campus dans lequel nous n'enseignons pas l'un des programmes que vous ayez choisi, veuillez vérifier les campus que vous avez sélectionnés"
            />
          ) : null}
        </FormJedha>
      </div>
    </div>
  );
};

export default CollectUserInfo;
