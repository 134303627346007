import React, { useState, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ClientSecret } from "./components/collectPayment";
import NavBar from "./components/navBar";
import CollectUserInfo from "./components/collectUserInfo";
import CollectIban from "./components/collectIban";
import Confirmation from "./components/confirmation";
import { BrowserRouter, Route, Switch } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const userInfo = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    postCode: "",
    enrolledCourses: [],
    price: "",
    client_secret: "",
    client_id: "",
    enrolledCoursesError: false,
    enrolledCampusError: false,
    certificationChoice: true,
  };
  const [values, setValues] = useState(userInfo);
  const providerValue = useMemo(
    () => ({ values, setValues }),
    [values, setValues]
  );

  return (
    <BrowserRouter>
      <div>
        <NavBar />
        <Elements stripe={stripePromise}>
          <ClientSecret.Provider value={providerValue}>
            <Switch>
              <Route exact path="/" component={CollectUserInfo} />
              <Route exact path="/payment-info" component={CollectIban} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route component={CollectUserInfo} />
            </Switch>
          </ClientSecret.Provider>
        </Elements>
      </div>
    </BrowserRouter>
  );
}

export default App;
