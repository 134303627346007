import React, { useState, useContext } from "react";
import { ClientSecret } from "./collectPayment";
import { installmentCalculator } from "../helpers/helpers";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Row, Col } from "react-bootstrap";
import { Icon, Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import {
  StepJedha,
  FormJedha,
  Fieldset,
  FieldTitle,
  Label,
  FormSubmit,
  SectionTitle,
  InstallmentPlanTitle,
  InstallmentPlanDefinition,
  InstallmentPlanExample,
  ReferenceSepa,
} from "../style/style";
import axios from "axios";

const CollectIban = () => {
  const { values, setValues } = useContext(ClientSecret);
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const installments = installmentCalculator(values);
  const [installmentPlan, setInstallmentPlan] = useState([]);
  const [ibanNumber, setIbanNumber] = useState(false);

  const handleIbanChange = (event) => {
    if (event.complete) {
      setIbanNumber(true);
    } else if (event.error) {
      setIbanNumber(false);
    }
  };

  const handlePaymentInstallements = (event, props) => {
    const chosenNumberOfInstallments = props.value;
    const firstInstallmentValue =
      Math.floor(values.price / chosenNumberOfInstallments) +
      (values.price % chosenNumberOfInstallments);
    const otherInstallmentsValues = Math.floor(
      values.price / chosenNumberOfInstallments
    );

    var tmpInstallmentPlan = [];
    for (let i = 0; i < chosenNumberOfInstallments; i++) {
      if (i === 0) {
        tmpInstallmentPlan[i] = firstInstallmentValue;
      } else {
        tmpInstallmentPlan[i] = otherInstallmentsValues;
      }
    }
    setInstallmentPlan(tmpInstallmentPlan);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const ibanElement = elements.getElement(IbanElement);

    const paymentMethod = await stripe
      .confirmSepaDebitSetup(values.client_secret, {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: {
            name: values.firstName + " " + values.lastName,
            email: values.email,
            address: {
              city: values.city,
              line1: values.address,
              postal_code: values.postCode,
            },
          },
        },
      })
      .then(function (response) {
        // Check if request succeeded
        if (response["setupIntent"]["status"] === "succeeded") {
          const payment_method = response["setupIntent"]["payment_method"];
          const setCustomerInstallment =
            process.env.REACT_APP_API_LINK + "set-customer-installment";

          const installmentInfo = {
            payment_method: payment_method,
            client_id: values.client_id,
            enrolled_courses: values.enrolledCourses,
            number_of_installments: installmentPlan.length.toString(),
            first_installment_amount: installmentPlan[0].toString(),
            installment_amount: installmentPlan[1]
              ? installmentPlan[1].toString()
              : "0",
            first_name: values.firstName,
            last_name: values.lastName,
          };

          axios
            .post(setCustomerInstallment, installmentInfo)
            .then(function (response) {
              let path = `/confirmation`;
              history.push(path);
            });
        }
      });
  };

  return (
    <div>
      <div className="d-none col-8 ml-auto mr-auto mt-4 d-md-flex justify-content-center">
        <StepJedha.Group unstackable fluid>
          <StepJedha completed>
            <Icon name="user" />
            <StepJedha.Content>
              <StepJedha.Title>Informations d'inscription</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
          <StepJedha active>
            <Icon name="payment" />
            <StepJedha.Content>
              <StepJedha.Title>Informations Bancaire</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
          <StepJedha>
            <Icon name="info" />
            <StepJedha.Content>
              <StepJedha.Title>Confirmation</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
        </StepJedha.Group>
      </div>
      <div className="col-sm-12 col-md-8 m-auto">
        <FormJedha
          className="p-4 mt-5 d-flex flex-column justify-content-center"
          onSubmit={handleSubmit}
        >
          <section>
            <FieldTitle className="mb-4">
              Prélèvements{" "}
              <span role="img" aria-label="dollars">
                💸
              </span>
            </FieldTitle>
            <Fieldset className="d-flex flex-column justify-content-center align-items-center pb-3">
              <Row className="m-0">
                <Col className="d-flex flex-column m-3 justify-content-center align-items-center">
                  <SectionTitle className="mb-0 text-center">
                    Votre Formation Data Science
                  </SectionTitle>
                  <p className="m-0 p-0" style={{ fontSize: "1.8rem" }}>
                    —
                  </p>
                </Col>
              </Row>
              <Row className="m-0">
                <Col>
                  <Label className="d-flex col-sm-12 col-md-10 m-auto justify-content-around align-items-center">
                    <span className="col-12 mr-3">Prix total</span>
                    <span className="col-sm-12">{values.price}€</span>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col className="col-sm-10 col-md-8 ml-auto mr-auto mt-4 d-flex flex-column justify-content-center ">
                  <InstallmentPlanTitle className="text-center">
                    Choisissez votre plan de paiement(s)
                  </InstallmentPlanTitle>
                  <InstallmentPlanDefinition className="text-center mb-0">
                    Si vous choisissez un plan de paiement en plusieurs fois, la
                    première mensualité est prélevée dès votre inscriptions et
                    définit le jour du prélèvements des autres mensualités
                  </InstallmentPlanDefinition>
                  <InstallmentPlanExample className="text-center">
                    Ex: Si vous vous inscrivez pour un plan de paiement en 3
                    fois le 15 d'un mois, vous serez prélevé tous les 15 de
                    chaque fois jusqu'à la fin de votre plan de paiement.
                  </InstallmentPlanExample>
                  <InstallmentPlanDefinition className="text-center mb-0">
                    Besoin de plus de paiement ?{" "}
                    <a href="mailto: admissions@jedha.co">
                      Contactez l'équipe d'admission
                    </a>{" "}
                    ou directement votre contact.
                  </InstallmentPlanDefinition>
                </Col>
              </Row>
              <Row className="m-0">
                <Col>
                  <Label className="d-flex col-sm-10 col-md-8 ml-auto mr-auto mt-3 justify-content-around align-items-center">
                    <span className="col-12 mr-3">Nombre de mensualités</span>
                    <Dropdown
                      className="col-8"
                      placeholder="Mensualités"
                      fluid
                      selection
                      options={installments}
                      onChange={handlePaymentInstallements}
                    />
                  </Label>
                </Col>
              </Row>
              {installmentPlan.map((installment, index) => (
                <React.Fragment key={index}>
                  <Row>
                    <Col className="d-flex col-8 ml-auto mr-auto mt-3 justify-content-around align-items-center">
                      <span className="col-12 text-right mr-2 p-0">
                        Mensualité {index + 1}
                      </span>
                      <span className="col-12 ml-1">{installment}€</span>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
            </Fieldset>
          </section>
          <section>
            <FieldTitle className="mb-4">
              Informations bancaires{" "}
              <span role="img" aria-label="100">
                💯
              </span>
            </FieldTitle>
            <Fieldset className="d-flex flex-column justify-content-center align-items-center">
              <Row className="m-0" style={{ width: "100%" }}>
                <Col>
                  <Label className="d-flex m-auto col-12">
                    <span className="text-right col-sm-3 col-md-4">IBAN</span>
                    <IbanElement
                      id="iban"
                      className="col-9"
                      options={{ supportedCountries: ["SEPA"] }}
                      onChange={handleIbanChange}
                    />
                  </Label>
                </Col>
              </Row>
            </Fieldset>
          </section>
          <Row>
            <Col>
              <ReferenceSepa className="mb-2 text-justify">
                En signant ce formulaire de mandat, vous autorisez (A) JEDHA SAS
                à envoyer des instructions à votre banque pour débiter votre
                compte, et (B) votre banque à débiter votre compte conformément
                aux instructions de JEDHA SAS. Vous bénéficiez du droit d’être
                remboursé par votre banque selon les conditions décrites dans la
                convention que vous avez passée avec elle. Une demande de
                remboursement doit être présentée dans les 8 semaines suivant la
                date de débit de votre compte pour un prélèvement autorisé.
              </ReferenceSepa>
            </Col>
          </Row>
          <FormSubmit
            className="m-auto col-12"
            variant="primary"
            type="submit"
            disabled={!stripe || installmentPlan.length === 0 || !ibanNumber}
          >
            S'INSCRIRE
          </FormSubmit>
        </FormJedha>
      </div>
    </div>
  );
};

export default CollectIban;
